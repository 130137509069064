import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
    return (
        <>
            <div className="container-fluid mt-5">
                <div className="footer">
                    <div>all right reserved by <Link to="https://bdmeditech.com">@bdmeditech</Link></div>
                </div>
            </div>
        </>
    );
}

export default Footer;