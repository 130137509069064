import React, {useState, useEffect} from "react";
import {Url} from '../../../contents/global.js';

const AccountList = () => {
    // const [acuntlst, setAcuntlst] = useState([]);
    // const getMasterData = async () => {
    //     let result = await fetch(Url+"admin/gtyp");
    //     result = await result.json();
    //     setAcuntlst(result);
    // }

    // useEffect(() => {
    //     getMasterData();
    // }, [])


    const [statement, setStatement] = useState("");
    const [typelst, setTypelst] = useState();
    const [selAcc, setSelAcc] = useState();
    const [types, setTypes] = useState('Dr');
    const [actypid, setActypid] = useState('');

    const SaveValue = async () => {
        let dt = JSON.stringify({
            statement: statement,
            kinds: types,
            id: actypid,
        });
        let result = await fetch(Url+"admin/typ", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: dt
        }).then(res => {GetLst();});
    }
    const GetLst = async () => {
        let result = await fetch(Url+"admin/gtyp");
        result = await result.json();
        setTypelst(result);
    }

    let setSelAccValue = (acc) => {
        setSelAcc(acc);
        setStatement(acc['statement']);
        setTypes(acc['kinds']);
        setActypid(acc['_id']);
    }

    useEffect(() => {
        GetLst();
        if(selAcc){
            
        }
    }, [selAcc]);


    return <div className="row">
        <div className="col-12"><h3>Account Types</h3></div>
        <div className="col-6">
            <div className="">{typelst ? typelst.map((acnt) => {
                return <div className="row" key={acnt._id}>
                    <div className="col-8">{acnt.statement}</div>
                    <div className="col-2">{acnt.kinds}</div>
                    <div className="col-2"><span className="btn btns1" onClick={() => setSelAccValue(acnt)}>Edit</span></div>
                </div>
            }):<div>Missing data</div>}</div>
        </div>
        <div className="col-6">
            <div className="fgroup">
                <label>Accrount Statement</label>
                <input type="text" onChange={(e) => setStatement(e.target.value)} placeholder="Accrount Statement" value={statement} />
            </div>
            <div className="fgroup"><label>Type</label>
            <select className="form-control" onChange={(e) => setTypes(e.target.value)} placeholder="Select Account type"  value={types}>
                <option value={""}>--- ---</option>
                <option value={"De"}>Debit</option>
                <option value={"Cr"} >Credit</option>
            </select></div>
        
            <div className="fgroup">
                <button className="btn btn2" onClick={SaveValue}>{actypid!=='' ? 'Update' : 'Save'}</button>
            </div>
        </div>
        <div className="col-12">
            
        </div>
    </div>
}

export default AccountList