import React, { useEffect, useState } from "react";
import { FcApproval } from "react-icons/fc";
import {Url} from '../../contents/global.js';
import secureLocalStorage from 'react-secure-storage';

const MealAcc = (props) => {
    const mess = JSON.parse(secureLocalStorage.getItem("mess"));
    const [sdate, setSdate] = useState();
    const [edate, setEdate] = useState();
    // const [ssdate, setSsdate] = useState();
    // const [sedate, setSedate] = useState();
    const [loading, setLoading] = useState(false);
    const [aggdata, setAggData] = useState([]);
    const [userlst, setUserLst] = useState([]);
    const [totalmeal, setTotalMeal] = useState(0);
    const [totalexpnc, setTotalExpnc] = useState(0);

    useEffect(() => {
        if(sdate==null) SetDate();
        getDatas();
    }, [sdate, edate]);

    const SetDate = () => {    
        let tday = new Date();
        setSdate(specDate(tday.getFullYear(),tday.getMonth(), 0, 2));
      
        setEdate(specDate(tday.getFullYear(),tday.getMonth(), 1, 1));
    }

    var specDate = (y, m, s, d) =>  new Date(y, m + s, d).toISOString().substring(0,10);  // 2021, 5, 1, 1 last date of month june
    const getDatas = () => {
        GetTrnsLst(); 
        GetSummary();
        GetUser();
    }

    const GetTrnsLst = async () => {
        if(sdate && edate){
            let url = `${Url}acc/agacc/${mess._id}/${sdate}/${edate}`;
            let result = await fetch(url);
            result = await result.json();
            setTotalExpnc(result[0].amount);
            // setTrnslist(result);
            // CalcuateTrns(result);
        }
    }

    const GetSummary = async () => {
        let url = `${Url}msmembers/${mess._id}/${sdate}`;
        if(edate!==null) url += `/${edate}`;
        if(sdate!==undefined){
            var result = await fetch(url);
            result = await result.json();
            setAggData(result);
            let meals = 0;
            result.forEach((rw, k)=>{
                meals += rw.breakfast + rw.lunch + rw.dinner;
            });
            setTotalMeal(meals);
        }
    }

    const GetUser = async () => {
        setLoading(true);
        var result = await fetch(`${Url}messuserlst/${mess._id}`);
        result = await result.json();
        let obj = {};
        result.forEach(elm => {
            obj[elm.uid._id] = elm.uid.name;
        });
        setUserLst(obj);
        setLoading(false);
    }


    return <><div className="row">
            <div className="col-12"><h4>Meal Account</h4></div>
            <div className="col-12 row">
                <div className="col-4">
                    <input type="date" className="form-control" onChange={(e) => setSdate(e.target.value)}  defaultValue={sdate} />
                </div>
                <div className="col-4">
                    <input type="date" className="form-control" onChange={(e) => setEdate(e.target.value)} defaultValue={edate} />
                </div>
                <div className="col-4">
                    <span className="float-end text-success"> {sdate!=null?new Intl.DateTimeFormat("en-GB", {month: "short", day: "2-digit" }).format(new Date(sdate)):''} - {edate!=null?new Intl.DateTimeFormat("en-GB", {month: "short", day: "2-digit" }).format(new Date(edate)):''}</span><button className="btn btn-outline-primary pt-1 pb-2" onClick={getDatas}><FcApproval /></button>
                </div>
                <div className="col-12">
                {loading ? <div className="loader"></div>:<>                    
                    <table className="table my-3">
                        <thead className="bg-primary py-3" style={{'--bs-bg-opacity': '0.25'}}>
                        <tr><th>Name </th><th>Breakfast</th><th>Lunch</th><th>Dinner</th><th>total</th><th>Taka</th>
                            {/* <th><button className="btn btn-outline-primary" onClick={getMeals}><FcRefresh /></button></th> */}
                        </tr></thead>
                        <tbody>
                        {aggdata.map((meal, ind) => (
                            <tr className="border-bottom" key={ind}>
                                <td className="py-2" onClick={()=>props.method(meal._id.uid)}>{userlst[meal._id.uid]}</td>
                                <td className="py-2">{meal.breakfast}</td>
                                <td className="py-2">{meal.lunch}</td>
                                <td className="py-2">{meal.dinner}</td>
                                <td className="py-2">{(meal.breakfast + meal.lunch + meal.dinner).toFixed(2)}</td>
                                <td className="py-2"><b>{((totalexpnc/totalmeal)*(meal.breakfast + meal.lunch + meal.dinner)).toFixed(0)} &#2547;</b></td>
                            </tr>
                        ))}
                        
                        </tbody>
                    </table></>}
                </div>
            </div>
        </div></>;
}

export default MealAcc;