import React from "react";
import { useEffect, useState } from "react";
import {Url} from '../../contents/global.js';
import {FcApproval, FcOvertime} from "react-icons/fc";


const MealFilter = (props) => {
    const user = JSON.parse(localStorage.getItem("user"));
    const [loading, setLoading] = useState(false);
    const [mealLst, setMealLst] = useState([]);
    let sdate = props.datas;
    let msid = props.msid;
    // let uid = props.uid;
    let auid = props.auid;

    useEffect(() => {
        sdate = props.datas;
        getDateMeals(sdate);
    }, [sdate]);

    const getDateMeals = async (date = null) => {
        let dte;
        if(date===null) dte = new Date();
        else dte = new Date(date);
        dte = dte.toISOString().substring(0,10);
        if(msid!=null){
            setLoading(true);
            let result = await fetch(Url+"getmeals/"+msid+"/"+dte);
            result = await result.json();
            // setMealLst(result);
            setMealLst(result);
            setLoading(false);
        }
    }
    
    const updateMeal = async (mids) => {
        let data = JSON.stringify({
            mealid: mids,
            uid: user._id,
        });
        let result = await fetch(Url+"aprvmeal", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: data
        })
        getDateMeals(sdate);
    }

    return (
        <div>
            <h5>Meals list <span className="float-end text-success"> {sdate!=null?new Intl.DateTimeFormat("en-GB", {month: "long", day: "2-digit" }).format(new Date(sdate)):''}</span>  </h5>
            <table className="table">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Bk</th>
                        <th>Lu</th>
                        <th>Di</th>
                        <th>Check</th>
                    </tr>
                </thead>
                <tbody>
                    {mealLst.map((itm, ind) => {
                        return (
                            <tr key={ind}>
                                <td>{itm.uid.name}</td>
                                <td>{itm.breakfast}</td>
                                <td>{itm.lunch}</td>
                                <td>{itm.dinner}</td>
                                <td>{itm.apvby? "OK": itm.creatby!=auid? <div className="btn btn-outline-primary pt-1" onClick={()=>updateMeal(itm._id)}><FcApproval /></div>:<FcOvertime />}</td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    )
}

export default MealFilter