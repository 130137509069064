import React, { createContext, useState, useEffect } from 'react';
import './App.css';
import Nav from './coponents/Nav';
import Footer from './coponents/Footer';
import Home from './pages/homepage';
import Signup from './pages/signup';
import Login from './pages/login';
import MealAdd from './pages/mealadder';
import MealList from './pages/meallistview';
import Messes from './pages/messhome';
import DetainMess from './pages/mess/messdetails';
import PrivateNav from './coponents/privateNav';
import Profile from './pages/user_profile';
import Accounce from './pages/accounce';
import SupHome from './pages/sup/adminhome';
import Masterdata from './pages/sup/masterdata';
import Userinfo from './pages/sup/manage_users';
import Messinfos from './pages/sup/messlist_manage';
import Useractivity from './pages/sup/user_activity';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HelpPage from './pages/helps';

export const ThemeContext = createContext(null);

function App() {

  const [theme, setTheme] = useState((localStorage.getItem('theme')) ? localStorage.getItem('theme') : 'light');
  const toggleTheme = () => {
    setTheme(theme === "light" ? "dark" : "light");
  }
  useEffect(() => {
    localStorage.setItem('theme', theme);
  }, [theme]);
  return (
    <ThemeContext.Provider value={{theme, setTheme}}>
      <div id={theme}>
        <Router>
          <Nav method={toggleTheme} value={theme} />
          <div className='p-3'>Hello</div>
          <div className='mt-5'>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route element={<PrivateNav />}>
              <Route path="/meals" element={<MealList />} />
              <Route path="/mealman" element={<MealAdd />} />
              <Route path="/mess" element={<Messes />} />
              <Route path="/mess/:id" element={<DetainMess />} />
              <Route path="/logout" element={<h1>Logout</h1>} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/account" element={<Accounce />} />
              <Route path="/suphome" element={<SupHome />} />
              <Route path="/master" element={<Masterdata />} />
              <Route path="/userinfo" element={<Userinfo />} />
              <Route path="/activity" element={<Useractivity />} />
              <Route path="/mesinfos" element={<Messinfos />} />
              <Route path="/helps" element={<HelpPage />} />
            </Route>
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<Signup />} />
          </Routes>
          </div>
          <Footer />
        </Router>
      </div>
    </ThemeContext.Provider>
  );
}

export default App;
