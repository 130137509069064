import React, { useState, useEffect } from "react";
import {Url} from '../../contents/global.js';
import secureLocalStorage from 'react-secure-storage';


const PayForm = (props) => {
    const user = JSON.parse(secureLocalStorage.getItem("user"));
    const mess = JSON.parse(secureLocalStorage.getItem("mess"));
    const [loading, setLoading] = useState(false);
    const [users, setUsers] = useState();
    const [pday, setPday] = useState();
    const [amount, setAmount] = useState(0);
    const [details, setDetails] = useState('');
    const [trtype, setTrtype] = useState('');
    const [pfromsel, setPformsel] = useState('');
    const [trtyp, setTrtyp] = useState('');
    const [pto, setPto] = useState();
    const [typelst, setTypelst] = useState();
    const [acclst, setAcclst] = useState();
    const [tarUser, setTarUser] = useState();
    const [isChecked, setIsChecked] = useState(false);
    const [selTid, setSelTid] = useState(null);

    const GetLst = async () => {
        let result = await fetch(Url+"acc/gtyp");
        result = await result.json();
        setTypelst(result);
    }
    const getUsers = async () => {
        setLoading(true);
        let result = await fetch(Url+"messuserlst/"+mess._id);
        result = await result.json();
        setUsers(result);
        setLoading(false);
    }

    useEffect(() => {
        GetLst();
        getUsers();
        if(props.trid!==null && Object.keys(props.trid).length>0) {
            setSelTid(props.trid._id);
            setPday(props.trid.ondate.substring(0,10));
            setAmount(props.trid.amount);
            setIsChecked(props.trid.tomeal);
            paidFormSet(props.trid.paidfrom);
            setPto(props.trid.paidto);
            setDetails(props.trid.details);
            setTarUser(props.trid.member._id);
            typelst.forEach(elm => {
                if(elm.statement === props.trid.paidfrom) {
                    setTrtype(elm);
                }
            });
        }
    }, [props.trid]);

    const setValues = (obj) => {
        // if(obj._id!==undefined){
        //     setSelTid(obj._id);
        //     setPform(obj.paidfrom);
        //     setPto(obj.paidto);
        //     setAmount(parseInt(obj.amount));
        //     setDetails(obj.details);
        //     if(obj.tomeal===undefined) setIsChecked(false);
        //     else setIsChecked(obj.tomeal);
        //     setTarUser(obj.member._id);
        //     setTrtyp(obj.kinds);
        //     setPday(obj.ondate.substring(0,10));
        // }
        // else {
        //     setSelTid(null);
        //     setPform('');
        //     setPto('');
        //     setAmount(0);
        //     setDetails('');
        //     setIsChecked(false);
        //     setTarUser(null);
        //     setTrtyp('');
        //     setPday('');
        // }
    }
    const SaveAccount = async () => {
        let obj = JSON.stringify({
            ondate: pday,
            paidfrom: pfromsel,
            paidto: pto,
            amount: amount,
            details: details,
            createdby: user._id,
            member: tarUser,
            kinds: trtyp,
            tomeal: isChecked,
            msid: mess._id,
        });
        let result = await fetch(Url+"acc/acsave", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: obj
        })
        result = await result.json();
        props.method();
        // setValues();
        resetFields();
    }
    const resetFields = () => {
        setSelTid(null);
        setPformsel('');
        setPto('');
        setAmount(0);
        setDetails('');
        setIsChecked(false);
        setTarUser(null);
        setTrtyp('');
        setPday('');
    }

    const updateAccount = async () => {
        let obj = JSON.stringify({
            id: selTid,
            ondate: pday,
            paidfrom: pfromsel,
            paidto: pto,
            amount: amount,
            details: details,
            createdby: user._id,
            member: tarUser,
            kinds: trtyp,
            tomeal: isChecked,
            msid: mess._id,
        })
        let result = await fetch(Url+"acc/acupdate", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: obj
        })
        result = await result.json();
        props.method();
    }

    const paidFormSet = (val) => {
        setPformsel(val);
        
        typelst.forEach(elm => {
            if(elm.statement === val) {
                // setTrtype(elm);
                setTrtyp(elm.kinds);
            }
        });
    }

    const updateValue = () => {
        if(props.trid!==null && props.trid!==undefined) setValues(props.trid);
    }


    return <div className="row" onClick={() => updateValue()}>
        <div className="col-5 mt-3">
            <label>Date</label>
            <input type="date" className="form-control" onChange={(e) => setPday(e.target.value)} defaultValue={pday} />
        </div>
        <div className="col-5 mt-3">
            <label>Amount</label>
            <input type="number" className="form-control" onChange={(e) => setAmount(e.target.value)} value={amount} />
        </div>
        <div className="col-2 mt-5">
            <label><input type="checkbox" id="topping" name="topping" onChange={(e) => setIsChecked(e.target.checked)} checked={isChecked} /> +Meal</label>
            {/* make check box selecte on data load */}
        </div>
        <div className="col-12 mt-3">
            <label>Transection  <small> -- {pfromsel}</small></label>
            <select className="form-control" onChange={(e) => paidFormSet(e.target.value)} value={pfromsel}>
                <option value={""}>--- ---</option>
                {typelst ? typelst.map((itm, ind) => { return <option key={ind} value={itm.statement} >{itm.statement}</option> }): ""}
            </select>
            {/* make select option selecte on data load */}
        </div>
        <div className="col-12 mt-3">
            <label>Target Person or service</label>
            <input type="text" className="form-control" onChange={(e) => setPto(e.target.value)} placeholder="Target Person or service" list="ptlist" value={pto} />
            <datalist id="ptlist">
            {props.plist.map((p,i) => <option key={i} value={p} />)}
            </datalist>
        </div>
        <div className="col-12 mt-3">
            <label>Related Mess Member</label>
            <div>
            {users ? users.map((itm, ind) => {
                return <button className={tarUser == itm.uid._id ? 'btn btn-outline-primary btn-sm active': 'btn btn-outline-primary btn-sm'} key={ind} onClick={()=>{setTarUser(itm.uid._id)}}>{itm.uid.name}</button>}): ""}
            </div>
        </div>
        <div className="col-12 mt-3">
            <label>Details</label>
            <textarea className="form-control" onChange={(e) => setDetails(e.target.value)} value={details}></textarea>
        </div>
        <div className="col-12 mt-3">
            {selTid===null ? <button className="btn btn-primary" onClick={SaveAccount}>Pay</button>:
            <><button className="btn btn-primary" onClick={updateAccount}>Update</button></>}
        </div>
        <div className="col-12 mt-3">
            {acclst ? acclst.map((itm, ind) => {
                return <div key={ind}>{itm.paidfrom}</div>
            }): ""}
        </div>
    </div>
}

export default PayForm