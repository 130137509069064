import React from "react";

const HelpPage = () => {


    const homeContent = () => {
        return (
            <>
                <div className="row">
                    <div className="col-12">
                        <h3>Types of user</h3>
                        <div>There are three kinds of user are maintained in the system. Different user role is different from each other. Some of them have vary basic access some other have admin access. User types are
                            <ul>
                                <li>General</li>
                                <li>Member</li>
                                <li>Admin</li>
                            </ul>
                            <dl>
                                <dt>General</dt>
                                <dd>General user access on the system is very limited. They can just view the home page and their personal profile page.</dd>
                                <dt>Member</dt>
                                <dd>Member can assign meal to any mess member, they can insert expenses for the mess. They also can see the monthly expenses and meal count total monthly payable amount also.<br /><b>How a person can be a member?</b><br /> When a admin includes a user to any mess that user becomes member user in the system.</dd>
                                <dt>Admin</dt>
                                <dd>Admin can do all the above and many more. Admin can create mess. they can insert member on their mess. They also can remove a member  from mess. <br /><b>How a user can be an admin?</b><br /> When a System admin approve any user admin request then that user becomes admin user in the system.</dd>
                            </dl>
                        </div>
                        <div>
                            <h4>The facilitate of the system</h4>
                            <h5>Planning menus:</h5>
                            <div>Bachelor mess managers need to plan menus that are both healthy and appealing to their residents. They also need to take into account dietary restrictions and preferences.</div>

                            <h5>Create a meal schedule:</h5>
                            <div>Plan out meals in advance to ensure variety and avoid last-minute confusion. Rotate responsibilities among members to share the cooking duties.</div>
                            <h5>Manage Expences:</h5>
                            <div>Bachelor mess managers need to track expences in a timely manner to ensure that their residents have access to accurate, details expence history. They also need to be mindful of budget constraints.</div>
                            <h5>Maintain a grocery list:</h5>
                            <div>Keep a shared list of necessary ingredients and supplies so that everyone can contribute to the shopping.</div>

                            <h5>Cooking meals:</h5>
                            <div>Bachelor mess managers need to be able to cook a variety of dishes to meet the needs of their residents. They also need to be able to follow recipes and cook meals efficiently.</div>
                            <h5>Set a budget:</h5>
                            <div>Determine a monthly budget for groceries and other expenses. Regularly review and adjust the budget if needed.</div>

                            <h5>Create a group chat:</h5>
                            <div>Set up a dedicated group chat on a messaging platform like WhatsApp or Slack to facilitate communication among members.</div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
    const MessContent = () => {
        return (
            <>
                <div className="row">
                    <div className="col-12">
                        <h3>Mess Create or Join a mess</h3>
                        <h4 className="mt-5">How to Create a mess</h4>
                        <ul className="list-group my-3">
                            <li className="list-group-item bg-transparent">First you have to change you user type. </li>
                            <li className="list-group-item bg-transparent">To do that Your have to send request to change your user type as admin</li>
                            <li className="list-group-item bg-transparent">Go to your profile from the menu bar</li>
                            <li className="list-group-item bg-transparent">Select user type as admin and click on update status button</li>
                            <li className="list-group-item bg-transparent">Wait untile your account was approved as an admin</li>
                        </ul>
                        <div>When your account was approved you will get full access to create and manage mess. A admin can let anyone join his mess. He can set meal to mess member and can approve meal counts. He can also remove a member from the mess. He can do lot more things.</div>

                        <h4 className="mt-5">How to Join a mess</h4>
                        <ul className="list-group my-3">
                            <li className="list-group-item bg-transparent">To join a mess you have to find your mess In the system</li>
                            <li className="list-group-item bg-transparent">First you have to go to your profile page</li>
                            <li className="list-group-item bg-transparent">In search mess section select District and Upazila/Thana where your mess located</li>
                            <li className="list-group-item bg-transparent">Then press search to search Your mess</li>
                            <li className="list-group-item bg-transparent">To specific search insert you mess name to get exact mass from huge list.</li>
                        </ul>
                        <div>You have to wait for the mess admin to approve activate your account as a mess member. When you become a mess member you can add a person meal as well as you meal for a particular day. You and insert expences. You will also can see the all the expensenes on behalf of the mess.</div>
                    </div>
                </div>
            </>
        )
    }

    const MealManage = () => {
        return (
            <>
                <div className="row">
                    <div className="col-12">
                        <h3>Meal Manage</h3>
                        <div>Here you can make entry of meals for indivisual mess member on prticular day. When a meal was added to a person it needs to be approved by some one else. User can update a meal before it was approved. When a meal was approved no one can update it. </div>
                    </div>
                </div>
            </>
        )
    }
    const MealsView = () => {
        return (
            <>
                <div className="row">
                    <div className="col-12">
                        <h3>Meal View</h3>
                        <div>Here you can check total number of meal consumed by a person. From here you also can approve meals. If you select a person then you can see that personl total meal count on that perticular month. </div>
                    </div>
                </div>
            </>
        )
    }
    const AccountMan = () => {
        return (
            <>
                <div className="row">
                    <div className="col-12">
                        <h3>Account Manage</h3>
                        <div>Here you can manage mess account. All kinds of expence are managed over here. You have to make a entry when you are paying house rent, shoping for the mess, paying money to cooker or reciving money from mess member. When you are doing some shoping you have to add it to meal. When you add it to meal then it will be calculated on meal expence for each member.</div>
                    </div>
                </div>
            </>
        )
    }
    const ProfileMan = () => {
        return (
            <>
                <div className="row">
                    <div className="col-12">
                        <h3>Profile page</h3>
                        <div>You can change your user type. You can also change your password. </div>
                    </div>
                </div>
            </>
        )
    }


    return (
        <div className="container" style={{ minHeight: '80vh' }}><div className="row">

            <div className="col-12">
                <h1 className="text-center">Help Page</h1>
            </div>
            <div className="col-12">

                <div className="row align-items-start">
                    <div className="nav flex-column col-2 nav-pills me-3 border-right pe-3" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                        <button className="btn btn-outline-primary mb-1 active" id="v-pills-home-tab" data-bs-toggle="pill" data-bs-target="#v-pills-home" type="button" role="tab" aria-controls="v-pills-home" aria-selected="true">Home</button>
                        <button className="btn btn-outline-primary mb-1" id="v-pills-messman-tab" data-bs-toggle="pill" data-bs-target="#v-pills-messman" type="button" role="tab" aria-controls="v-pills-messman" aria-selected="false">Mess Manage</button>
                        <button className="btn btn-outline-primary mb-1" id="v-pills-mealman-tab" data-bs-toggle="pill" data-bs-target="#v-pills-mealman" type="button" role="tab" aria-controls="v-pills-mealman" aria-selected="false">Meal Manage</button>
                        <button className="btn btn-outline-primary mb-1" id="v-pills-meals-tab" data-bs-toggle="pill" data-bs-target="#v-pills-meals" type="button" role="tab" aria-controls="v-pills-meals" aria-selected="false">Meal View</button>
                        <button className="btn btn-outline-primary mb-1" id="v-pills-account-tab" data-bs-toggle="pill" data-bs-target="#v-pills-account" type="button" role="tab" aria-controls="v-pills-account" aria-selected="false">Accounce</button>
                        {/* <button className="btn btn-outline-primary mb-1" id="v-pills-disabled-tab" data-bs-toggle="pill" data-bs-target="#v-pills-disabled" type="button" role="tab" aria-controls="v-pills-disabled" aria-selected="false" disabled>Disabled</button> */}
                    </div>
                    <div className="col tab-content" id="v-pills-tabContent">
                        <div className="tab-pane fade show active" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab" tabIndex="0">{homeContent()}</div>
                        <div className="tab-pane fade" id="v-pills-messman" role="tabpanel" aria-labelledby="v-pills-messman-tab" tabIndex="0">{MessContent()}</div>
                        <div className="tab-pane fade" id="v-pills-mealman" role="tabpanel" aria-labelledby="v-pills-disabled-tab" tabIndex="0">{MealManage()}</div>
                        <div className="tab-pane fade" id="v-pills-meals" role="tabpanel" aria-labelledby="v-pills-meals-tab" tabIndex="0">{MealsView()}</div>
                        <div className="tab-pane fade" id="v-pills-account" role="tabpanel" aria-labelledby="v-pills-account-tab" tabIndex="0">{AccountMan()}</div>
                    </div>
                </div>

            </div>

        </div></div>
    );
}

export default HelpPage;