import React from "react";
import { useNavigate, Link } from "react-router-dom";
import {Url, config} from '../contents/global.js';
import img9 from '../assets/imgs/pics/img07.jpg';
import {FcPrevious} from 'react-icons/fc';
import secureLocalStorage from 'react-secure-storage';


const Login = () => {
    const [email, setEmail] = React.useState("");
    const [password, setPassword] = React.useState("");
    const navigate = useNavigate();
    const handleLogin = async () => {
        let data = JSON.stringify({
            uid: email,
            password: password
        });
        let result = await fetch(Url+"login", {
            method: "POST",
            headers: config,
            body: data
        });
        result = await result.json();
        if (result.message === "password") {
            alert('Password does not match');
        } else if(result.message==="user"){alert('User does not exist');} else {
            if(result[0].active===true) secureLocalStorage.setItem("role", result[0].role);
            secureLocalStorage.setItem("user", JSON.stringify(result[0]));
            if(result[1]) secureLocalStorage.setItem("mess", JSON.stringify(result[1].messid));
            navigate("/");
        }
    }
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
          // 👇 Get input value
          handleLogin();
        }
      };


    return (
        <div className="container-fluid" style={{'margin': '-105px 0 -40px'}}>
            <div className="row justify-content-center">
                <div className="col-6" style={{background: 'url(' + img9 + ')', 'minHeight': '100vh', 'paddingTop': '105px', 'backgroundSize': 'cover'}}>
                    <h1 className="text-end mt-10 txt2 tx1" style={{'fontSize': '100px', 'fontWeight': '900'}}>Login</h1>                    
                </div>
                <div className="col-6 mt-5" style={{'paddingTop': '105px'}}>
                    <div className="p-5 px-4 py-5 float-start" style={{'width': '400px'}}>
                    {/* <form> */}
                    
                    <div className="fgroup mb-4 sptx"><label>Email or Userid</label>
                        <input className="" type="text" value={email} placeholder="Email or Userid" onChange={(e)=>setEmail(e.target.value)} />
                    </div>
                    <div className="fgroup mb-4 sptx"><label>Password</label>
                        <input className="" type="password" value={password} placeholder="Password" onChange={(e)=>setPassword(e.target.value )} onKeyDown={handleKeyDown} />
                    </div>
                    <div className="fgroup mb-4">
                        <Link className="btn btno3 me-3" to="/signup"><FcPrevious /> Let join</Link>
                        <button className="btn btn4 me-3 float-end" onClick={handleLogin}>Login</button>
                    </div>
                    {/* </form> */}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Login;