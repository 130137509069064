import React from "react";
import { useParams } from "react-router";
import {Url} from '../../contents/global.js';


const DetainMess = () => {
    let { id } = useParams();
    
    return (
        <div className="container-fluid mt-5" style={{minHeight:'85vh'}}>
            <div className="row justify-content-center">
                <div className="col-12">
                    <h1>Detail about Mess is {id}</h1>
                </div>
            </div>
        </div>
    );
}

export default DetainMess;