import React, {useState, useEffect} from "react";
import TableData from "./meals/allmeallist";
import {Url} from '../contents/global.js';
import MealSum from "./meals/sumup_view";
import secureLocalStorage from 'react-secure-storage';


const MealList = () => {
    const user = JSON.parse(secureLocalStorage.getItem("user"));
    const mess = JSON.parse(secureLocalStorage.getItem("mess"));
    const [sdate, setDate] = useState();
    const [ssdate, setSsDate] = useState();
    const [edate, setEDate] = useState();
    const [loading, setLoading] = useState(false);
    
    var lastday = (y,m) => new Date(y, m +1, 0).getDate();

    const changeDates = (e) => {
        setDate(e.target.value);
    }
    const cngEndDates = (e) => {
        setEDate(e.target.value);
    }

    const dateDiff = (sdate, edate) => {
        var date1 = new Date(sdate);
        var date2 = new Date(edate);
        var diffTime = -1*(date1.getTime() - date2.getTime()) + 1;
        var diffDays = (diffTime!==0)? Math.ceil(diffTime / (1000 * 3600 * 24)): 0;
        return diffDays;
    }
    useEffect(() => {
        var date = new Date();
        date = date.toISOString().substring(0,10);
        setSsDate(date);
        setDate(date);
        setEDate(date);
    }, []);

    const setUserId = (uid) => {
    }

    return (
        <div className="container" style={{minHeight:'85vh'}}>
            <div className="row">
                <div className="col-12">
                    <div className="card crd1 w-100"><div className="card-body pb-2"><h3>User Meal List</h3>
                    <div className="row justify-content-center pb-3">
                        <div className="col-5 d-flex">
                            <div className="w-50 py-2 text-end">Start Date: &nbsp;</div><input type="date" className="form-control" name="dates" onChange={changeDates} defaultValue={ssdate} /></div>
                        <div className="col-5 d-flex">
                            <div className="w-50 py-2 text-end">End Date: &nbsp;</div><input type="date" className="form-control" name="dates" onChange={cngEndDates} /></div>
                        <div className="col-12 pt-3">
                            <span className="float-end text-secondary"> &nbsp; = {dateDiff(sdate, edate)} days</span>
                            <span className="float-end text-success">{sdate!=null?new Intl.DateTimeFormat("en-GB", {month: "long", day: "2-digit" }).format(new Date(sdate)):''} - {edate!=null?new Intl.DateTimeFormat("en-GB", {month: "long", day: "2-digit" }).format(new Date(edate)):''}</span> 
                        </div>
                    </div>
                    </div></div>
                </div>

                {/* <div className="col-2"><button className="btn btn-primary" onClick={getMeals}>Get Meals</button></div> */}
                
                <div className="col-12 mt-4">
                <div className="card crd1 w-100"><div className="card-body pb-2"><MealSum mid={mess._id} sdate={sdate} edate={edate} method={setUserId} /></div></div>
                </div>
                <div className="col-12 mt-4">
                <div className="card crd1 w-100"><div className="card-body pb-2"><TableData mid={mess._id} uid={user._id} sdate={sdate} edate={edate} /></div></div>
                </div>
            </div>
        </div>
    )
}

export default MealList;