import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import secureLocalStorage from 'react-secure-storage';

const PrivateNav = () => {
    const auth = JSON.parse(secureLocalStorage.getItem("user"));
    return auth ? <Outlet /> : <Navigate to="/signup "/>;
}


export default PrivateNav;