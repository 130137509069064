import React, {useEffect, useState} from "react";
// import { useNavigate, Link } from "react-router-dom";
import ActMember from "./meals/activemember";
import MealMan from "./meals/mealaddform";
import MealFilter from "./meals/mealondate";
import {Url} from '../contents/global.js';
import secureLocalStorage from 'react-secure-storage';

const MealAdd = () => {
    const user = JSON.parse(secureLocalStorage.getItem("user"));
    const mess = JSON.parse(secureLocalStorage.getItem("mess"));
    const [loading, setLoading] = useState(false);
    const [selMess, setMesses] = useState((mess!=null) ? mess._id: '');
    const [suid, setSuid] = useState([]);
    const [mdate, setMdate] = useState();
    const chedkValue = (vl) => {
        setSuid(vl);
    }

    const setDates = (event) => { setMdate(event.target.value); }

    useEffect(() => {
        if(mdate==null){  
            let dte = new Date();
            dte = dte.toISOString().substring(0,10);
            setMdate(dte);
        }
    });

    const getMeals = async () => {
        setLoading(true);
        let date = mdate;
        let dte = new Date('2011-04-11T10:20:30Z');
        dte = dte.toISOString().substring(0,10);
        setMdate(dte);
        setTimeout(()=>{setMdate(date);}, 500);
        setLoading(false);
    }
    
    return (
        <div className="container" style={{minHeight:'85vh'}}>
            <div className="row">
                <div className="col-12 d-flex my-4">
                    <div className="card crd1 w-100"><div className="card-body d-flex"><h1>Manage Meal</h1>
                    </div></div>
                </div>
                <div className="col-7">
                    <div className="card crd1 shadow"><div className="card-body"><div className="row">
                        <div className="col-5">
                        <ActMember mids={selMess} uid={suid} method={chedkValue} />
                        </div><div className="col-7">
                        <div className="my-2">
                            <input type="date" className="form-control" name="date" onChange={setDates} defaultValue={mdate} />
                        </div>

                            {(suid!=="") ? <><MealMan mid={selMess} uid={suid} handeler={getMeals} sdate={mdate} />
                        </>: <div className="alert alert-warning" role="alert">Please select a member</div>}
                        </div>
                    </div></div></div>
                </div>
                <div className="col-5">
                    <div className="card crd1 shadow"><div className="card-body" ><MealFilter uid={suid} datas={mdate} auid={user._id} msid={mess._id} /></div></div>
                </div>
            </div>
        </div>
    )
}

export default MealAdd;