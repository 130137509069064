// const Url = 'http://localhost:5000/'
const Url = 'https://msdb.bdjobsguru.com/'
const themeDefault = 'dark'
const namesOfModes = ['dark', 'light']

let config =  {
    "Content-Type": "application/json",
    'Access-Control-Allow-Origin': '*',
    "Access-Control-Allow-Headers": "Content-Type, Authorization, X-Requested-With"
    }

export { Url, themeDefault, namesOfModes, config }