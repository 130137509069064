import React, { useEffect } from "react";
import Lastactive from "./sub/lastactive";
import secureLocalStorage from 'react-secure-storage';
import { useNavigate } from 'react-router-dom';

const Useractivity = () => {
    const navigate = useNavigate();
    const auth = JSON.parse(secureLocalStorage.getItem('user'));
    useEffect(() => {
        if(auth.role!=='supuser' || auth.active!==true) navigate("/");
    }, [auth]);

    return <section><div className="container" style={{minHeight: '80vh'}}><div className="row">
        <h1>User Activity page</h1>
        <Lastactive />
    </div></div></section>   
}

export default Useractivity;