import React, {useState, useEffect} from 'react';
import {Url, config} from '../contents/global.js';
import secureLocalStorage from 'react-secure-storage';


const Profile = () => {
    let user = JSON.parse(secureLocalStorage.getItem('user'));
    let [userid, setUserid] = useState(user._id);
    let [name, setName] = useState(user.name);
    let [email, setEmail] = useState(user.email);
    let [role, setRole] = useState("");
    const [oldPass, setOldPass] = useState("");
    const [newPass, setNewPass] = useState("");
    const [retPass, setRetPass] = useState("");
    const [userlst, setUsers] = useState([]);
    const [upzila, setUpzila] = useState("");
    const [district, setDistrict] = useState("");
    const [upazList, setUpazList] = useState([]);
    const [distList, setDistList] = useState([]);
    const [load1, setLoad1] = useState(false);
    const [load2, setLoad2] = useState(false);
    const [serc, setSearch] = useState("");
    const [messLst, setMesslst] = useState([]);

    useEffect(() => {
        if(user.roll==='admin' && user.active===true){
            getUserList();
        }
        getDisUpoa();
    }, []);

    const getDisUpoa = async () => {
        setLoad2(true);
        let result = await fetch(Url+"upazila");
        result = await result.json();
        setUpazList(result);
        let dis = await fetch(Url+"district");
        dis = await dis.json();
        setDistList(dis);
        setLoad2(false);
    }
    const getUserList = async () => {
        setLoad1(true);
        let result = await fetch(Url+"userlst");
        result = await result.json();
        setUsers(result);
        setLoad1(false);
    }
    const setUserInfo = async () => {
        const data = JSON.stringify({
            uid: user._id,
            name: name,
            email: email,
            role: role,
        })
        let url = Url+"updateuser";
        let result = await fetch(url, {
            method: "POST",
            headers: config,
            body: data
        });
        console.warn(JSON.stringify(result));
    }
    const isExist = (obj) => {
        if(district===obj.disid_id)
            return true;
        else
            return false;
    }
    const searchMess = async () => {
        let url = Url+"searchmess/"+district+"/"+upzila+"/"+serc;
        let result = await fetch(url);
        result = await result.json();
        console.warn(result);
        setMesslst(result);
    }
    const addToMess = async (mid) => {
        let data = JSON.stringify({
            uid: user._id,
            messid: mid,
            status: "created"
        });
        let result = await fetch(Url+"uaddremove", {
            method: "POST",
            headers: config,
            body: data
        });
        result = await result.json();
    }
    const updatePass = async () => {
        if(newPass && retPass && oldPass && newPass === retPass){
            let data = JSON.stringify({
                uid: user._id,
                oldpass: oldPass,
                newpass: newPass
            });
            let result = await fetch(Url+"updatepass", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: data
            });
            result = await result.json();
            if(result['message']==='saved') alert("Password Changed Successfully");
        }
        else { 
        }
    }
    const SetCustOldPass = (e) => {
        setOldPass(e);
    }

    return (
        <div className='container' style={{minHeight: '80vh'}}>
            <div className='row'>
                <div className='col-12'>
                    { load1 ? <div className='loader'></div> : <><h1>{user.name}</h1>
                    <div>email: {user.email}</div>
                    <div>role: {user.role}</div>
                    <div>Approve: {user.active}</div>
                    </>}
                </div>
                <div className='col-4'>
                    <h3>User Type</h3>
                    {load2 ? <div className="loader"></div> : secureLocalStorage.getItem('role')!=='supuser' ? <>
                    <div className='row'>
                        <div className='col-12 p-3'>
                            <label htmlFor="name" className='p-1'>Name</label>
                            <input type="text" className='form-control' name="name" defaultValue={user.name} onChange={(e)=>setName(e.target.naem)} />
                        </div>
                        <div className='col-12 p-3'>
                            <label htmlFor="email" className='p-1'>email</label>
                            <input type="text" className='form-control' name="email" defaultValue={user.email} onChange={(e)=>setEmail(e.target.email)} disabled />
                        </div>
                        <div className='col-12 p-3'>
                            <label htmlFor="role" className='w-100 p-1'>Role : </label>
                            <label className='px-3'><input type='radio' name='role' value="admin" onChange={()=>setRole('admin')} /> Admin</label>
                            <label className='px-3'><input type='radio' name='role' value="user" onChange={()=>setRole('member')} /> Member </label>
                            
                        </div>
                        <div className='col-12 p-3'>
                            <button className='btn btn-primary' onClick={setUserInfo}>Update Status</button>
                        </div>
                    </div>
                    </>: ''}
                    <h3>Password</h3>
                    {load2 ? <div className="loader"></div> : <>
                    <div className='row'>
                        <div className='col-12 p-3'>
                            <label className='p-1'>Existing Password</label>
                            <input type="password" className='form-control' name="opass" onChange={(e)=>setOldPass(e.target.value)} placeholder='Existing Password' />
                        </div>
                        <div className='col-12 p-3'>
                            <label className='p-1'>New Password</label>
                            <input type="password" className='form-control' name="npass" onChange={(e)=>setNewPass(e.target.value)} placeholder='New password' />
                        </div>
                        <div className='col-12 p-3'>
                            <label className='p-1'>Retype New Password</label>
                            <input type="password" className='form-control' name="rpass" onChange={(e)=>setRetPass(e.target.value)} placeholder='Retype new password' />
                        </div>
                        <div className='col-12 p-3'>
                            <button className='btn btn-primary' onClick={updatePass}>Reset password</button>
                        </div>
                    </div>
                    </>}
                </div>
                <div className='col row'>
                    {load2 ? <div className="loader"></div> : <div className="col-5">
                        <h3>Search Mess</h3>
                        <label className='px-3 pt-3'>District</label>
                        <select className="form-control" name="dist" onChange={(e)=>setDistrict(e.target.value)}>
                            <option value={0}>---</option>
                            {distList.map((dis) => (
                                <option key={dis._id} value={dis.id}>{dis.name}</option>
                            ))}
                        </select>
                        <label className='px-3 pt-3'>Upazila/Thana</label>
                        <select className="form-control" name="upzila" placeholder="Upazila Name" onChange={(e)=>setUpzila(e.target.value)}>
                        {upazList.map((upz) => (
                            isExist(upz) ? <option key={upz._id} value={upz.id}>{upz.name}</option> : ""
                        ))}
                        </select>
                        <input type="text" className="form-control mt-3" name="search" onChange={(e)=>setSearch(e.target.value)} placeholder="Search By Name" />
                        <button className="btn btn-primary" onClick={searchMess}>Search</button>
                    </div>}
                    <div className="col-7">
                        {messLst ? messLst.map((mess) => <div key={mess._id} className='card p-2'>
                            <h4>{mess.name} <sup>({mess.members})</sup></h4>
                            <p>{mess.address}, {mess.area}</p>
                            <div>{mess.createdby.name}</div>
                            <div><button className='btn btn-primary' onClick={()=>addToMess(mess._id)}>Add</button></div>
                        </div>) : ""}
                    </div>
            </div>
        </div>
    </div>
    )
}

export default Profile;