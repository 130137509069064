import React from "react";
import { BiEdit } from "react-icons/bi";
import {Url} from '../../contents/global.js';


const LstMeal = (props) => {
    let mealist = props.meallst;
    return (
        <>
            <ul className="list-group">
                {mealist.map((meal, index) => (
                    <li className="list-group-item" key={index}>
                        <div>
                            <small className="text-primary">{new Intl.DateTimeFormat("en-GB", {month: "long", day: "2-digit" }).format(new Date(meal.date))}</small>
                            {!meal.approved?<span className="" onClick={()=>props.method(meal)}><i className="btn btn-primary btn-sm float-end"><BiEdit /></i> </span>: <span className="text-success  float-end">OK</span>}
                        </div>
                        <div className=" d-flex"><i className="flex-fill">Br: {meal.breakfast},</i>
                        <i className="flex-fill">Lu: {meal.lunch},</i>
                        <i className="flex-fill">Di: {meal.dinner}</i> </div> 
                    </li>
                ))}
            </ul>
        </>
    )
}

export default LstMeal;