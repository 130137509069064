import React from 'react';
import { Link } from 'react-router-dom';
import avater from '../assets/imgs/icons/avater.png';
import { useNavigate, useLocation } from 'react-router-dom';
import { FcOrgUnit, FcHome, FcSalesPerformance, FcInspection, FcAcceptDatabase, FcQuestions, FcSettings, FcCancel, FcImport } from 'react-icons/fc';
import { FaSun, FaMoon } from 'react-icons/fa';
import secureLocalStorage from 'react-secure-storage';

const Nav = (props) => {
    const auth = JSON.parse(secureLocalStorage.getItem('user'));
    const role = secureLocalStorage.getItem("role");
    const mess = JSON.parse(secureLocalStorage.getItem("mess"));
    // console.log(mess.name);
    const navigate = useNavigate();
    const location = useLocation();
    
    let aurl = location.pathname;
    const logout = () => {
        secureLocalStorage.clear();
        navigate("/");
    }
    return (
      <div className="fixed-top">
<nav className="navbar navbar-expand-lg">
  <div className="container-fluid">
    <a className="navbar-brand" href="/#">BMMS</a>
    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>
    <div className="collapse navbar-collapse" id="navbarSupportedContent">
      <ul className="navbar-nav me-auto mb-2 mb-lg-0">
        <li className="nav-item"><Link className={aurl==="/" ? "nav-link active" : "nav-link"} to="/"> <FcHome /> Home</Link></li>
        { auth ? role==="admin" ? <li className='nav-item'><Link className={aurl=='/mess' ? 'nav-link active' : 'nav-link'} to="/mess"><FcOrgUnit /> Mess</Link></li> : "" : "" }
        { auth ? mess!==null? <li className='nav-item'><Link className={aurl=='/mealman' ? 'nav-link active' : 'nav-link'} to="/mealman"><FcSalesPerformance /> Meals Man</Link></li> : "" : "" }
        { auth ? mess!==null? <li className='nav-item'><Link className={aurl=='/meals' ? 'nav-link active' : 'nav-link'} to="/meals"><FcInspection /> Meals</Link></li> : "" : "" }
        { auth ? mess!==null? <li className='nav-item'><Link className={aurl=='/meals' ? 'nav-link active' : 'nav-link'} to="/account"><FcAcceptDatabase /> Accounce</Link></li> : "" : "" }
        { auth ? role==="supuser" ? 
        <li className='nav-item'>
          <div className="dropdown">
            <div className="dropdown-toggle p-2" type="button" data-bs-toggle="dropdown" aria-expanded="false">
              Manage
            </div>
            <ul className="dropdown-menu">
              <li><Link className={aurl==='/suphome' ? 'dropdown-item active' : 'dropdown-item'} to="/suphome">Summary</Link></li>
              <li><Link className={aurl==='/userinfo' ? 'dropdown-item active' : 'dropdown-item'} to="/userinfo">User Info</Link></li>
              <li><Link className={aurl==='/activity' ? 'dropdown-item active' : 'dropdown-item'} to="/activity">User Activity</Link></li>
              <li><Link className={aurl==='/mesinfos' ? 'dropdown-item active' : 'dropdown-item'} to="/mesinfos">Mess Info</Link></li>
              <li><Link className={aurl==='/master' ? 'dropdown-item active' : 'dropdown-item'} to="/master">Account Data</Link></li>
            </ul>
          </div>
        </li> : "" : "" }
      </ul>
    </div>
    <div className='d-flex'>
      <ul className="navbar-nav me-auto mb-2 mb-lg-0">
        <li>
          <div className="text-center pt-1" style={{width: "150px"}}><div onClick={props.method} className="btn btnso1">{props.value==='dark'? <FaSun /> : <FaMoon /> }</div></div>
        </li>
        <li className="nav-item dropdown d-flex">
          <div className=''>{auth? auth.name: ''}<br/><small className="fw-light" style={{fontSize: '80%'}}>{mess? mess.name: ''}</small></div>
          <a className="nav-link dropdown-toggle" data-bs-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false"><img className='avatar rounded-circle' style={{ width: '30px', height: '30px' }} src={avater} /></a>
          <div className="dropdown-menu dropdown-menu-end">

            { auth ? <>
              <Link className={aurl==='/profile' ? 'dropdown-item active' : 'dropdown-item'} to="/profile"><FcSettings /> Profile</Link>
              <Link className={aurl==='/helps' ? 'dropdown-item active' : 'dropdown-item'} to="/helps"><FcQuestions /> Help</Link>
            </> : "" }
            <div className="dropdown-divider"></div>
            { auth ?  <Link className='dropdown-item' onClick={logout} to="/login"><FcCancel /> Logout</Link>: <Link className='dropdown-item' to="/login"><FcImport /> Login</Link>}
          </div>
        </li>
      </ul>
    </div>
  </div>
</nav>
        </div>
    );
}

export default Nav;