import React, {useEffect, useState} from "react";
import { useNavigate, Link } from "react-router-dom";
import {Url, config} from '../contents/global.js';
import img9 from '../assets/imgs/pics/img08.jpg';
import {FcNext} from 'react-icons/fc';
import secureLocalStorage from 'react-secure-storage';

const Signup = () => {
    const [userid, setUserid] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [password, setPassword] = useState("");
    const [conpassword, setConPassword] = useState("");
    const [name, setName] = useState("");
    const [uidenb, setUidenb] = useState(false);
    const [emailenb, setEmailenb] = useState(false);
    const navigate = useNavigate();
    useEffect(() => {
        const auth = JSON.parse(secureLocalStorage.getItem("user"));
        if (auth) {
            navigate("/");
        }
    },[]);
    const collectData = async () => {
        if(password === conpassword){
            let result = await fetch(Url+"signup", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    userid: userid,
                    email: email,
                    phoen: phone,
                    password: password,
                    name: name
                })
            });
            result = await result.json();
            if(result.message === "User Already Exist"){
                alert("User Already Exist");
            }
            else{
                localStorage.setItem("user", JSON.stringify(result));
                alert('Login Successfully');
                navigate("/");
                
            }            
        }
        else { setPssmatch(false)}
    }

    const checkEmail = (val) => {
        const expReg = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/gi;
        let res = val.match(expReg);
        if(res!==null)  return true;
        else            return false;
    }

    const checkUserid = async (val) => {
        let dta;
        let cks =  checkEmail(val);
        if(checkEmail(val)) dta = JSON.stringify({email: val})
        else                dta = JSON.stringify({userid: val})
        let result = await fetch(Url+"getuser", {
            method: "POST",
            headers: config,
            body: dta
        });
        result = await result.json();
        if(result === true){
            if(cks) setEmailenb(true);
            else    setUidenb(true);
        }
        else {
            if(cks) setEmailenb(false);
            else    setUidenb(false);
        }
    }

    const [pssmatch, setPssmatch] = useState(true);
    const matchPass = () => {
        if(conpassword !== "")
            if(password === conpassword){
                setPssmatch(true);
            } else {
                setPssmatch(false);
            }
    }


    return (
        <div className="container-fluid" style={{'margin': '-105px 0 -40px'}}>
            <div className="row justify-content-center">
                <div className="col-6" style={{'paddingTop': '105px'}}>
                    <div className="px-4 py-5 float-end" style={{'width': '400px'}}>
                            <div className="mb-3">
                                <div className="fgroup mb-4 sptx sptx">
                                    <label>Name</label>
                                    <input className="" type="text" placeholder="Full Name" value={name} onChange={(e)=>setName(e.target.value)} />
                                </div>
                                <div className="fgroup mb-4 sptx">
                                    <label>User ID <sup className="text-danger">{uidenb? '(ID is in use)':''}</sup></label><input className="{uidenb ? 'border-danger text-danger': ''}" type="text" placeholder="User id" value={userid} onChange={(e)=>setUserid(e.target.value)} onBlur={(e)=>checkUserid(e.target.value)} /></div>
                                <div className="fgroup mb-4 sptx">
                                    <label>email  <sup className="text-danger">{emailenb? '(email is in use)':''}</sup></label><input className="" type="text" placeholder="Email" value={email} onChange={(e)=>setEmail(e.target.value)} onBlur={(e)=>checkUserid(e.target.value)} /></div>
                                <div className="fgroup mb-4 sptx">
                                    <label>Phone (optional)</label><input className="" type="text" placeholder="Phone (optional)" value={phone} onChange={(e)=>setPhone(e.target.value)} /></div>
                                <div className="fgroup mb-4 sptx">
                                    <label>Password <sup className="text-danger">{!pssmatch? 'Password does not match':''}</sup></label><input className="" type="password" placeholder="Password" value={password} onChange={(e)=>setPassword(e.target.value)} onBlur={(e)=>matchPass()} /></div>
                                <div className="fgroup mb-4 sptx">
                                    <label>Confirm Password</label><input className="" type="password" placeholder="Confirm Password" value={conpassword} onChange={(e)=>setConPassword(e.target.value)} onBlur={(e)=>matchPass()} /></div>
                                <div className="mb-3 mt-4">
                                    <input className="btn btn4 me-3" type="submit" value="Register" onClick={collectData} />

                                    <Link className="btn btno3 me-3 float-end" to="/login">Login <FcNext /></Link>
                                </div>
                            </div>
                    </div>
                </div>
                <div className="col-6" style={{background: 'url(' + img9 + ')', 'minHeight': '100vh', 'paddingTop': '105px', 'backgroundSize': 'cover'}}>
                    
                    <h1 className="mt-10 txt2 tx1" style={{'fontSize': '80px', 'fontWeight': '900'}}>Registration</h1></div>

                    
                </div>
            </div>
    )
}

export default Signup;