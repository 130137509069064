import React, {useState, useEffect} from "react";
import { FcApproval, FcDownload, FcNext, FcPrevious, FcServices } from "react-icons/fc";
import { MdFastfood } from "react-icons/md";
import { Url } from "../../contents/global.js";
import secureLocalStorage from 'react-secure-storage';

const Trnsections = (props) => {
    const mess = JSON.parse(secureLocalStorage.getItem("mess"));
    const [trnslist, setTrnslist] = useState([]);
    const [sdate, setSdate] = useState();
    const [edate, setEdate] = useState();
    const [selMonth, setSelMonth] = useState();
    const [debits, setDebits] = useState();
    const [credits, setCredits] = useState();
    const [paytolst, setPaytolst] = useState([]);

    var specDate = (y, m, s, d) =>  new Date(y, m + s, d).toISOString().substring(0,10);  // 2021, 5, 1, 1 last date of month june

    useEffect(() => {
        if(sdate===null) SetDate();
        GetTrnsLst();
    }, [sdate, edate]);

    const SetDate = () => {    
        let tday = new Date();
        setSdate(specDate(tday.getFullYear(),tday.getMonth(), 0, 2));
        setEdate(specDate(tday.getFullYear(),tday.getMonth(), 1, 1));
        GetTrnsLst();
    }

    const nxtMonth = (vl) => {
        let tday = new Date(sdate);
        setSdate(specDate(tday.getFullYear(),tday.getMonth(), vl, 2));
        setEdate(specDate(tday.getFullYear(),tday.getMonth(), vl+1, 1));
        GetTrnsLst();
    }
    // const getOnClick = () => {
    //     var lastDay = new Date(sdate.getFullYear(), sdate.getMonth() + 1, 0);
    //     setEdate(lastDay);
    //     if(dateDiffInDays(sdate,edate)<25 && dateDiffInDays(sdate,edate)>40) getOnClick();
    //     else GetTrnsLst();
    // }
    function dateDiffInDays(a, b) {
        const _MS_PER_DAY = 1000 * 60 * 60 * 24;
        // Discard the time and time-zone information.
        const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
        const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());      
        return Math.floor((utc2 - utc1) / _MS_PER_DAY);
    }

    const GetTrnsLst = async () => {
        let url = Url+"acc/gtacc/"+mess._id;
        if(sdate) url += "/"+sdate;
        if(edate) url += "/"+edate;
        let result = await fetch(url);
        result = await result.json();
        setTrnslist(result);
        CalcuateTrns(result);
    }
    const [ssdate, setSsdate] = useState();
    const [sedate, setSedate] = useState();
    const CalcuateTrns = (dta) => {
        setPaytolst([]);
        var de = 0;
        var cr = 0;
        let obj = [];
        dta.forEach(rws => {
            if(rws.kinds==='De') de+=parseFloat(rws.amount);
            if(rws.kinds==='Cr') cr+=parseFloat(rws.amount);
            obj.push(rws.paidto)
        });
        setDebits(de);
        setCredits(cr);
        if(sdate){
            let dt = new Date(sdate);
            dt.setDate(dt.getDate() + 1);
            setSsdate(dt.toISOString().substring(0,10));
        }
        if(edate){
            let dt = new Date(edate);
            dt.setDate(dt.getDate() + 1);
            setSedate(dt.toISOString().substring(0,10));
        }
        setPaytolst(obj);
        props.addList(obj);
    }
    const approve = async (ids) => {
        let obj = JSON.stringify({
            id: ids,
            aprovedby: props.uid,
        })
        let result = await fetch(Url+"acc/acupdate", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: obj
        });
        GetTrnsLst();
    }

    return <div className="container">
        <div className="row">
            <div className="col-12"><h4>Transaction List </h4>
            </div>
            <div className="col-12">
                <div className="row my-2">
                    
                    {/* <div className="col-3"><button className="btn btn-outline-primary pt-1 pb-2" onClick={getOnClick}><FcDownload /></button></div> */}
                </div>
            </div>
            <div className="col-12">
                <div className="row">
                    <div className="col-4">
                        <input type="date" className="form-control" onChange={(e) => setSdate(e.target.value)}  defaultValue={sdate} />
                    </div>
                    <div className="col-4">
                        <input type="date" className="form-control" onChange={(e) => setEdate(e.target.value)} defaultValue={edate} />
                    </div>
                    <div className="col-4"><div className="row">
                        <div className="col-3"><button className="btn btn-outline-primary pt-1 pb-2 float-end" onClick={() => nxtMonth(-1)}><FcPrevious /></button></div>
                        <div className="col-3 text-center">{sdate!=null?new Intl.DateTimeFormat("en-GB", {month: "long"}).format(new Date(sdate)):''}</div>
                        <div className="col-3"><button className="btn btn-outline-primary pt-1 pb-2" onClick={() => nxtMonth(1)}><FcNext /></button></div>
                        <div className="col-3"><button className="btn btn-outline-primary pt-1 pb-2" onClick={GetTrnsLst}><FcApproval /></button></div>
                        </div>
                        
                        <span className="text-success"> {sdate!=null?new Intl.DateTimeFormat("en-GB", {month: "short", day: "2-digit" }).format(new Date(sdate)):''} - {edate!=null?new Intl.DateTimeFormat("en-GB", {month: "short", day: "2-digit" }).format(new Date(edate)):''}</span>
                    </div>
                </div>
            </div>
            <table className="table">
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>Paid From</th>
                        <th colSpan={2}>Amount</th>
                        {/* <th>Paid To</th>
                        <th>Details</th> */}
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {trnslist.length>0 ? trnslist.map((trns, i) => {
                        return <tr key={i}>
                            <td className="py-2">{new Intl.DateTimeFormat("en-GB", {month: "short", day: "2-digit", year: "2-digit" }).format(new Date(trns.ondate))}<br /> {trns.createdby? trns.createdby.name: ''}</td>
                            <td className="py-2">{trns.paidfrom}<br />{trns.member? trns.member.name: ''}</td>
                            <td className="py-2">{trns.kinds==='De'? trns.amount: ''}</td>
                            <td className="py-2">{trns.kinds==='Cr'? trns.amount: ''}</td>
                            <td className="py-2">{trns.tomeal===true || trns.tomeal=== "true" ? <MdFastfood />: ''}</td>
                            {/* <td>{trns.paidto}</td>
                            <td>{trns.details}</td> */}
                            <td className="py-2">
                                {trns.createdby ? props.uid===trns.createdby._id ? <button className="btn btn-outline-primary pt-1 pb-2" onClick={() => props.method(trns)} data-bs-toggle="modal"  data-bs-target="#transections"><FcServices /></button>: trns.aprovedby ? <small>{trns.aprovedby.name}</small> : <button className="btn btn-outline-primary pt-1 pb-2" onClick={() => approve(trns._id)} ><FcApproval /></button>: ''}
                            </td>
                        </tr>
                    }): ''}
                    <tr><td colSpan={2}></td><td><b>{debits}</b></td><td><b>{credits}</b></td></tr>
                </tbody>
            </table>
        </div>
    </div>
}

export default Trnsections