import React, {useEffect, useState} from "react";
import {Url} from '../../contents/global.js';
import secureLocalStorage from 'react-secure-storage';
import { useNavigate } from 'react-router-dom';

const Messinfos = () => {
    const navigate = useNavigate();
    const auth = JSON.parse(secureLocalStorage.getItem('user'));
    useEffect(() => {
        if(auth.role!=='supuser' || auth.active!==true) navigate("/");
    }, [auth]);
    
    const [messlst, setMesslst] = useState([]);
    const getMessInfo = async () => {
        let result = await fetch(Url+"admin/getmess");
        result = await result.json();
        setMesslst(result);
    }
    useEffect(() => {
        getMessInfo();
    }, []);

    const changeStatus = async (svid,state) => {
        let dta = JSON.stringify({id: svid, status: state});
        let result = await fetch(Url+"admin/cngstatus/", {
            method: "POST",
            headers: {"Content-Type": "application/json"},
            body: dta
        });
        result = await result.json();
        console.log(result);
        getMessInfo();
    }

    return <section><div className="container" style={{minHeight: '80vh'}}><div className="row">
        <h1>Mess information page</h1>
        <table>
            <thead><tr><th>Name</th><th>Address</th><th>area</th><th>Creat By</th><th>Action</th></tr></thead>
            <tbody>
                {messlst ? messlst.map((item, index) => {
                    return <tr key={index}>
                        <td>{item.name}</td>
                        <td>{item.address}</td>
                        <td>{item.area}</td>
                        <td>{item.createdby.name}</td>
                        <td>
                            <button className={item.status === 'active' ? 'btn btn-danger': 'btn btn-success' } onClick={() => changeStatus(item._id,item.status === "active" ? "inactive" : "active")}>{item.status === "active" ? "inactive" : "active"}</button>
                        </td>
                    </tr>
                }): "Mess Missing"}
            </tbody>
        </table>
    </div></div></section>   
}

export default Messinfos;