import React, {useEffect, useState} from "react";
import { Link } from "react-router-dom";
import MessMember from "./mess/messmember";
import {SlList} from "react-icons/sl";
import {Url, config} from '../contents/global.js';
import secureLocalStorage from 'react-secure-storage';

const Matchs = () => {
    const user = JSON.parse(secureLocalStorage.getItem("user"));
    const mess = JSON.parse(secureLocalStorage.getItem("mess"));
    const [name, setName] = useState("");
    const [address, setAddress] = useState("");
    const [area, setArea] = useState("");
    const [upzila, setUpzila] = useState("");
    const [district, setDistrict] = useState("");
    const [members, setMembers] = useState("");
    const [selmess, setSelmess] = useState(null);
    const [loading, setLoading] = useState(false);
    const [upazList, setUpazList] = useState([]);
    const [distList, setDistList] = useState([]);
    const [musers, setMusers] = useState([]);

    const setMess = (m) => {
        secureLocalStorage.setItem("mess", JSON.stringify(m));        
    }

    const saveMatchs = async () => {
        const data = JSON.stringify({
            name: name,
            address: address,
            area: area,
            upzila: upzila,
            district: district,
            members: members,
            createdby: user._id,
        });
        let url = Url+"messcreate";
        if(selmess!==null)
            url = Url+"messupdate/"+selmess;
            
        await fetch(url, {
            method: "POST",
            headers: config,
            body: data
        });
        getMess();        
    }
    const selMessOn = (e) => {
        setSelmess(e._id);
        setName(e.name);
        setAddress(e.address);
        setArea(e.area);
        setUpzila(e.upzila);
        setDistrict(e.district);
        setMembers(e.members);
    }
    const [messlst, setMesses] = useState([]);
    useEffect(() => {
        getMess();
        getDisUpoa();
    }, []);

    const getDisUpoa = async () => {
        let result = await fetch(Url+"upazila");
        result = await result.json();
        setUpazList(result);
        let dis = await fetch(Url+"district");
        dis = await dis.json();
        setDistList(dis);
    }
    const getMess = async () => {
        setLoading(true);
        const data = JSON.stringify({
            uid: user._id,
        })
        let result = await fetch(Url+"getmess", {
            method: "POST",
            headers: config,
            body: data
        });
        result = await result.json();
        setMesses(result);
        setLoading(false);
    }
    const isExist = (obj) => {
        if(district===obj.disid_id)
            return true;
        else
            return false;
    }
    const resetMess = () => {
        setSelmess(null);
        setName(null);
        setAddress(null);
        setArea(null);
        setUpzila(null);
        setDistrict(null);
        setMembers(null);
    }
    useEffect(() => {
        getMusers();
    }, [selmess]);

    const getMusers = async () => {
        setLoading(true);
        if(selmess!==null){
            let result = await fetch(Url+"messuserlst/"+selmess);
            result = await result.json();
            setMusers(result);
        }
        setLoading(false);
    }

    return (
        <div className="container-fluid mt-5" style={{minHeight:'85vh'}}>
            <div className="row justify-content-center">
                <div className="col-6">
                    <h4><SlList /> List                     
                    <button type="button" className="btn btn2 btn-sm float-end" data-bs-toggle="modal" data-bs-target="#messForm" onClick={resetMess}>Create</button></h4>
        {/* <li className="nav-item"><Link className='nav-link' to="/mess/hello world">One Mess</Link></li> */}
                    <div className="row">
                        {messlst.map((meses, ind) => (
                            <div className="col-12 p-3" key={meses._id} onClick={()=>selMessOn(meses)}>
                                <div className="card crd1 my-3">
                                    <div className={mess._id===meses._id? "card-header bg-warning": 'card-header'}><h5 className="d-inline">{meses.name} </h5> 
                                    <button type="button" className="btn btn-primary btn-sm float-end mx-1" onClick={()=>setMess(meses)}>Active</button>
                                    <button type="button" className="btn btn-primary btn-sm float-end mx-1" data-bs-toggle="modal" data-bs-target="#messMembers">Add Member</button>
                                    <button type="button" className="btn btn-primary btn-sm float-end ms-1" data-bs-toggle="modal" data-bs-target="#messForm">Edit</button>
                                    <Link to={"/mess/"+meses._id} className="btn btn-outline-secondary btn-sm float-end" >Detail</Link>
                                    </div>
                                    <div className="card-body"> 
                                        { selmess !== meses._id ?  '' : <>
                                            {musers.map((el, k)=>(
                                                <b key={k} className="m-1 py-2 px-3 d-inline-block border">{el.uid.name}</b>
                                            ))}
                                        </>}
                                    </div>
                                </div>
                            </div>
                        ))}
                        {loading? <div className="loader"></div>:''}

                    </div>
                </div>
                <div className="modal fade" id="messMembers" tabIndex="-1" aria-labelledby="messMembersLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="messMembersLabel">{name}</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div>
                                {selmess!==null? <MessMember ids={selmess} />: ''}
                            </div>
                        </div>
                    </div>
                </div>
                </div>

            </div>

            <div className="modal fade" id="messForm" tabIndex="-1" aria-labelledby="messFormLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="messFormLabel">{selmess===null? "Create": "Edit"}  Mess </h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="mt-3">
                                <input type="text" name="name" className="form-control" value={name} onChange={(e)=>setName(e.target.value)} placeholder="Match Name" />
                            </div>
                            <div className="mt-3">
                                <select className="form-control" name="dist" onChange={(e)=>setDistrict(e.target.value)}>
                                    <option value={0}>---</option>
                                    {distList.map((dis) => (
                                        <option key={dis._id} value={dis.id}>{dis.name}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="mt-3">
                                <select className="form-control" name="upzila" placeholder="Upazila Name" onChange={(e)=>setUpzila(e.target.value)}>
                                    {upazList.map((upz) => (
                                        isExist(upz) ? <option key={upz._id} value={upz.id}>{upz.name}</option> : ""
                                    ))}
                                </select>
                            </div>
                            <div className="mt-3">
                                <input type="text" name="area" className="form-control" value={area} onChange={(e)=>setArea(e.target.value)} placeholder="In which area it is" />
                            </div>
                            <div className="mt-3">
                                <input type="text" name="address" className="form-control" value={address} onChange={(e)=>setAddress(e.target.value)} placeholder="Address to define location" />
                            </div>
                            <div className="mt-3">
                                <input type="number" name="members" className="form-control" value={members} onChange={(e)=>setMembers(e.target.value)} placeholder="Maximum member it can acomodate" />
                            </div>
                            <div className="mt-3">
                                <button className="btn btn-primary me-3" onClick={saveMatchs}>{selmess===null? "Submit": "Edit"}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Matchs;