import React, { useEffect } from "react";
import AccountList from "./sub/accounts";
import secureLocalStorage from 'react-secure-storage';
import { useNavigate } from 'react-router-dom';

const Masterdata = () => {
    const navigate = useNavigate();
    const auth = JSON.parse(secureLocalStorage.getItem('user'));
    useEffect(() => {
        if(auth.role!=='supuser' || auth.active!==true) navigate("/");
    }, [auth]);
    
    return <section><div className="container" style={{minHeight: '80vh'}}><div className="row">
        <h1>Master data page</h1>
        <div className="col-12">
            <AccountList />
        </div>
    </div></div></section>   
}

export default Masterdata;