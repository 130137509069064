import React, {useState, useEffect} from "react";
import {FcCheckmark} from "react-icons/fc";
import {Url} from '../../contents/global.js';

//   load all the active members in a mess

const ActMember = (props) => {
    const ids = props.mids;
    const [musers, setMusers] = useState([]);
    useEffect(() => {
        if(musers.length===0) getMusers();
    })
    const getMusers = async () => {
        let result = await fetch(Url+"messuserlst/"+ids);
        result = await result.json();
        setMusers(result);
    }
    return (
        <div>
            <h5>Active Members</h5>
            <ul className="list-group">
                {musers.map((user, index) => (
                    <li className='list-group-item text-start' key={index} onClick={()=>props.method([user.uid._id, user.uid.name])} ><div className={props.uid===user.uid._id? "text-primary": ''}>{user.uid.name} <div className="badge border border-success float-end position-absolute end-0 me-2">{user.status==="active"? <FcCheckmark />: ""}</div></div></li>
                ))}
            </ul>
        </div>
    )
}

export default ActMember;