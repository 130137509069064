import React, {useState, useEffect} from "react";
import {Url} from '../../../contents/global.js';

const Lastactive = () => {
    const [activelst, setActivelst] = useState([]);
    const loadUserInfo = async () => {
        let result = await fetch(Url+"admin/active");
        result = await result.json();
        setActivelst(result[0]);
    }

    useEffect(() => {
        loadUserInfo();
    }, [])


    return <div className="row">
        <div className="col-12"><h3>Active users</h3></div>
        <div className="col-12"><table className="table"><tbody>
        {!activelst? '': activelst.map((user) => {
            return <tr key={user._id}><td>{user._id}</td><td>{user.role}</td><td>{user.ldate}</td><td></td></tr>
        })}</tbody></table></div>
    </div>
}

export default Lastactive