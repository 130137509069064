import React, {useState, useEffect} from "react";
import {FcCheckmark} from "react-icons/fc";
import UMLst from "./meallist";
import {Url, config} from '../../contents/global.js';
import secureLocalStorage from 'react-secure-storage';


const MealMan = (props) => {
    const user = JSON.parse(secureLocalStorage.getItem("user"));
    const mid = props.mid;
    const uid = props.uid[0];
    const name = props.uid[1];
    const mdate = props.sdate;
    const [sMeal, setSMeal] = useState("");
    const [breakfast, setBreakfast] = useState(false);
    const [bk_value, setBkvalue] = useState(0);
    useEffect(() => {
        let curr = new Date();
        // curr.setDate(curr.getDate());
        let date = curr.toISOString().substring(0,10);
        // setMdate(date);
    }, [])

    useEffect(() => {
        if(sMeal!==""){
            let meal = sMeal;
            if(meal.breakfast > 0) setBreakfast(true); else setBreakfast(false);
            setBkvalue(meal.breakfast);
            if(meal.lunch >0) setLunch(true); else setLunch(false);
            setLnvalue(meal.lunch);
            if(meal.dinner >0) setDinner(true); else setDinner(false);
            setDnvalue(meal.dinner);
            let dte = new Date(sMeal.date);
            dte = dte.toISOString().substring(0,10);
            // setMdate(dte);
        }
    }, [sMeal]);
    const chngBreakfast = () => {
        // setBreakfast(!breakfast);
        if(breakfast===false) {
            setBreakfast(true);
            setBkvalue(1);
        } else {
            setBreakfast(false);
            setBkvalue(0);
        }
    }
    const chngBk_value = (vl) => { setBkvalue(vl); }
    const [lunch, setLunch] = useState(false);
    const [ln_value, setLnvalue] = useState(0);
    const chngLunch = () => {
        if(lunch === false) {
            setLunch(true);
            setLnvalue(1);
        } else {
            setLunch(false);
            setLnvalue(0);
        }
    }
    const chngLn_value = (vl) => { setLnvalue(vl); }
    const [dinner, setDinner] = useState(false);
    const [dn_value, setDnvalue] = useState(0);
    const chngDinner = () => {
        if(dinner === false) {
            setDinner(true);
            setDnvalue(1);
        } else {
            setDinner(false);
            setDnvalue(0);
        }
    }
    const chngDn_value = (vl) => { setDnvalue(vl); }
    
    const updateMeal = async () => {
        let data = JSON.stringify({
            mealid: sMeal._id,
            creatby: user._id,
            breakfast: bk_value,
            lunch: ln_value,
            dinner: dn_value,
        });
        await fetch(Url+"updatemeals", {
            method: "POST",
            headers: config,
            body: data
        });
        setSMeal("");
        props.handeler();
    }
    const saveMeal = async () => {
        let data = JSON.stringify({
            uid: uid,
            messid: mid,
            creatby: user._id,
            breakfast: bk_value,
            lunch: ln_value,
            dinner: dn_value,
            date: new Date(mdate),
        });
        await fetch(Url+"mealcreat", {
            method: "POST",
            headers: config,
            body: data
        });
        
        props.handeler();
    }
    const formCompRadio = (cusFunc, vname, rname) => {
        return (
            <div className="d-flex">
                <label className="flex-fill border text-center"><input type="radio" name={rname} onChange={()=>cusFunc(4)} checked={vname===4} /> 4 </label>
                <label className="flex-fill border text-center"><input type="radio" name={rname} onChange={()=>cusFunc(3)} checked={vname===3} /> 3 </label>
                <label className="flex-fill border text-center"><input type="radio" name={rname} onChange={()=>cusFunc(2)} checked={vname===2} /> 2 </label>
                <label className="flex-fill border text-center"><input type="radio" name={rname} onChange={()=>cusFunc(1)} checked={vname===1} /> 1 </label>
                <label className="flex-fill border text-center"><input type="radio" name={rname} onChange={()=>cusFunc(0.75)} checked={vname===0.75} /> 3/4 </label>
                <label className="flex-fill border text-center"><input type="radio" name={rname} onChange={()=>cusFunc(0.5)} checked={vname===0.5} /> 1/2 </label>
                <label className="flex-fill border text-center"><input type="radio" name={rname} onChange={()=>cusFunc(0.25)} checked={vname===0.25} /> 1/4 </label>
            </div>
        );
    }

    return (
        <div className="container">
            <div className="row">
                <div className="col-12">
                <h4>Set Meal for {name}</h4>
                    <div className="mb-3 text-start">                        
                        <label className="form-control"> <input type="checkbox" className="form-check-input" placeholder="Name" name="breakfast" value={breakfast} onChange={chngBreakfast} /> Breakfast {bk_value}
                            { breakfast? formCompRadio(chngBk_value, bk_value, 'bkvalue') : ""}
                        </label>
                    </div>
                    <div className="mb-3 text-start">                        
                        <label className="form-control"> <input type="checkbox" className="form-check-input" placeholder="Name" name="name" value={lunch} onChange={chngLunch} /> Lunch {ln_value}
                            { lunch ? formCompRadio(chngLn_value, ln_value, 'lavalue'): ''}
                        </label>
                    </div>
                    <div className="mb-3 text-start">                        
                        <label className="form-control"> <input type="checkbox" className="form-check-input" placeholder="Name" name="name" value={dinner} onChange={chngDinner} /> Dinner
                            { dinner ? formCompRadio(chngDn_value, dn_value, 'dnvalue'): ''}
                        </label>                    
                    </div>
                    
                    <div className="mb-3">
                        { (sMeal!=="")? <button className="btn btn-primary me-3" onClick={updateMeal}>Update</button> : 
                        <button className="btn btn-primary me-3" onClick={saveMeal}>Save</button>}
                    </div>
                </div>
                <div className="col-12">
                    <UMLst mid={mid} uid={uid} sdt={mdate} method={setSMeal} />
                </div>
            </div>
        </div>
    )
}

export default MealMan