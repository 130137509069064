import React, {useState, useEffect} from "react";
import {Url} from '../../../contents/global.js';

const UserList = () => {
    const [userlst, setUserlst] = useState([]);
    const [urequest, setUrequest] = useState([]);
    const [ugener, setUgener] = useState([]);
    const loadUserInfo = async () => {
        let result = await fetch(Url+"admin/user");
        result = await result.json();
        setUserlst(result[0]);
        setUrequest(result[1]);
        setUgener(result[2]);
    }
    useEffect(() => {
        loadUserInfo();
    }, []);


    const approveAdmin = async (id) => {
        let obj = JSON.stringify({
            id: id
        })
        let result = await fetch(Url+"admin/useraprove", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: obj
        });
        loadUserInfo();
    }

    return <div className="row">
        <div className="col-6"><h3>User List page</h3></div>
        <div className="col-6"><h3>User List</h3><table className="table">
        {!userlst? '': userlst.map((user) => {
            return <tr key={user._id}><td>{user._id}:</td><td> {user.count}</td></tr>
        })}
        </table></div>
        <div className="col-6"><h3>Admin Request</h3><table className="table">
        {!urequest? '': urequest.map((user) => {
            return <tr key={user._id}><td>{user.name}:</td><td> <b>{user.role}</b> </td><td><button onClick={() => approveAdmin(user._id)} className="btn btno1" >Approve</button></td></tr>
        })}</table></div>
        <div className="col-6"><h3>User Request</h3><table className="table">
        {!ugener? '': ugener.map((user) => {
            return <tr key={user._id}><td>{user.name}: </td><td><b>{user.role}</b> </td><td><button onClick={() => approveAdmin(user._id)} className="btn btno1" >Approve</button></td></tr>
        })}</table></div>
    </div>
}

export default UserList;