import React, { useEffect, useState } from "react";
import { Url } from "../../contents/global";

const MealSum = (props) => {
    let mid = props.mid;
    const [loading, setLoading] = useState(false);
    const [aggdata, setAggData] = useState([]);
    const [userlst, setUserLst] = useState([]);

    useEffect(() => {
        GetSummary();
        GetUser();
    }, [props.sdate, props.edate]);

    const GetSummary = async () => {
        let url = `${Url}msmembers/${mid}/${props.sdate}`;
        if(props.edate!==null) url += `/${props.edate}`;
        if(props.sdate!==undefined){
            var result = await fetch(url);
            result = await result.json();
            setAggData(result);
        }
    }

    const GetUser = async () => {
        setLoading(true);
        var result = await fetch(`${Url}messuserlst/${mid}`);
        result = await result.json();
        let obj = {};
        result.forEach(elm => {
            obj[elm.uid._id] = elm.uid.name;
        });
        setUserLst(obj);
        setLoading(false);
    }

    return (
        <div className="row">
            <div className="col-12">
                <h5>Meal Summary</h5>
                {loading ? <div className="loader"></div>:<>                    
                    <table className="table my-3">
                        <thead className="bg-primary py-3" style={{'--bs-bg-opacity': '0.25'}}>
                        <tr><th>Name </th><th>Breakfast</th><th>Lunch</th><th>Dinner</th><th>total</th>
                            {/* <th><button className="btn btn-outline-primary" onClick={getMeals}><FcRefresh /></button></th> */}
                        </tr></thead>
                        <tbody>
                        {aggdata.map((meal, ind) => (
                            <tr className="border-bottom" key={ind}>
                                <td className="py-2" onClick={()=>props.method(meal._id.uid)}>{userlst[meal._id.uid]} <small className="text-muted">({meal.total})</small></td>
                                <td className="py-2">{meal.breakfast}</td>
                                <td className="py-2">{meal.lunch}</td>
                                <td className="py-2">{meal.dinner}</td>
                                <td className="py-2">{meal.breakfast + meal.lunch + meal.dinner}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table></>}
            </div>
        </div>
    )
}

export default MealSum;