import React from "react";
import messimg from '../assets/imgs/pics/mess0.png';
import diagram from '../assets/imgs/pics/bmms.png';
import account from '../assets/imgs/icons/account.svg';
import budget from '../assets/imgs/icons/budget.svg';
import chat from '../assets/imgs/icons/chat.svg';
import cooking from '../assets/imgs/icons/cooking.svg';
import dish from '../assets/imgs/icons/dish.svg';
import foods from '../assets/imgs/icons/foods.svg';
import manage from '../assets/imgs/icons/manage.svg';
import shoping from '../assets/imgs/icons/shopping.svg';
import img1 from '../assets/imgs/pics/img01.jpg';
import img2 from '../assets/imgs/pics/img02.jpg';
import img3 from '../assets/imgs/pics/img03.jpg';
import img4 from '../assets/imgs/pics/img04.jpg';
import img5 from '../assets/imgs/pics/img05.jpg';
import img6 from '../assets/imgs/pics/img06.jpg';
import {Url} from '../contents/global.js';
import { useEffect } from "react";
import { Slide, Fade } from "react-awesome-reveal";

const Home = () => {
    // const GetLst = async () => {
    //     let result = await fetch(Url+"district");
    //     result = await result.json();
    // }
    // const chekObj = async () => {
    //     let obj = {1: "hello world"};
    //     return obj;
    // }

    // useEffect(() => {
    //     GetLst();
    // }, [])

    return (
        <div className="" style={{minHeight:'85vh'}}>
            <div className="section">
                <div className="container">
                    <div className="row">
                        <div className="col-5">
                            <Fade cascade triggerOnce><h1 className="display-4 my-5"><b className="text-success">B</b>achelor <b className="text-primary text-uppercase"><b className="text-success">M</b>ess</b> <b className="text-success">M</b>anagement <b className="text-success">S</b>ystem </h1>
                            <div className="mb-5">We All Know that Bachelor <b>Mess</b> Managing nightmare. Now BMMS turn it into fun, easy to Manage <b>Meals</b> and Track <b>Expenses</b> also <b>Message</b> between member</div></Fade>
                        </div>
                        <div className="col-7">
                            <Slide direction='right' triggerOnce><img className="img-fluid my-5" src={messimg} alt="messimg" /></Slide>
                        </div>
                    </div>
                </div>
            </div>
            <div className="section">
                <div className="container-fluid" style={{backgroundColor: "#f5edea"}}>
                    <div className="row">
                        <div className="col-12">
                            <div className="my-5 text-center"><Fade cascade triggerOnce><img className="img-fluid my-5 mx-auto" src={diagram} alt="System diagram" /></Fade></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="section">
                <div className="container">
                    <div className="row">
                        <div className="col-12 my-5">
                            <h4 className="text-center mb-0">Here are some of the key aspects of </h4><div className="display-6 text-center mb-5">Bachelor Mess Managers System <b className="text-warning">(BMMS)</b></div>
                            <div className="row justify-content-center">
                                <div className="col-4 my-3"><Fade direction='left' triggerOnce><div className="card card3">
                                    <div className="row g-0">
                                        <div className="col-3">
                                            <img src={foods} className="img-fluid my-5 px-3" alt="..." />
                                        </div>
                                        <div className="col-9">
                                    <div className="card-body"><h5>Planning menus:</h5> Bachelor mess managers need to plan menus that are both healthy and appealing to their residents. They also need to take into account dietary restrictions and preferences.</div></div></div></div></Fade></div>
                                <div className="col-4 my-3"><Fade direction='down' triggerOnce><div className="card card2">
                                    <div className="row g-0">
                                        <div className="col-3">
                                            <img src={dish} className="img-fluid my-5 px-3" alt="..." />
                                        </div>
                                        <div className="col-9">
                                    <div className="card-body"><h5>Create a meal schedule:</h5> Plan out meals in advance to ensure variety and avoid last-minute confusion. Rotate responsibilities among members to share the cooking duties.</div></div></div></div></Fade></div>
                                <div className="col-4 my-3"><Fade direction='right' triggerOnce><div className="card card3">
                                    <div className="row g-0">
                                        <div className="col-3">
                                            <img src={account} className="img-fluid my-5 px-3" alt="..." />
                                        </div>
                                        <div className="col-9">
                                    <div className="card-body"><h5>Manage Expences:</h5> Bachelor mess managers need to track expences in a timely manner to ensure that their residents have access to accurate, details expence history. They also need to be mindful of budget constraints.</div></div></div></div></Fade></div>
                                <div className="col-4 my-3"><Fade direction='left' triggerOnce><div className="card card2">
                                    <div className="row g-0">
                                        <div className="col-3">
                                            <img src={shoping} className="img-fluid my-5 px-3" alt="..." />
                                        </div>
                                        <div className="col-9">
                                    <div className="card-body"><h5>Maintain a grocery list:</h5> Keep a shared list of necessary ingredients and supplies so that everyone can contribute to the shopping.</div></div></div></div></Fade></div>
                                <div className="col-4 my-3"><Fade direction='down' triggerOnce><div className="card card3">
                                    <div className="row g-0">
                                        <div className="col-3">
                                            <img src={cooking} className="img-fluid my-5 px-3" alt="..." />
                                        </div>
                                        <div className="col-9">
                                    <div className="card-body"><h5>Cooking meals:</h5> Bachelor mess managers need to be able to cook a variety of dishes to meet the needs of their residents. They also need to be able to follow recipes and cook meals efficiently.</div></div></div></div></Fade></div>
                                <div className="col-4 my-3"><Fade direction='right' triggerOnce><div className="card card2">
                                    <div className="row g-0">
                                        <div className="col-3">
                                            <img src={budget} className="img-fluid my-5 px-3" alt="..." />
                                        </div>
                                        <div className="col-9">
                                    <div className="card-body"><h5>Set a budget:</h5> Determine a monthly budget for groceries and other expenses. Regularly review and adjust the budget if needed.</div></div></div></div></Fade></div>
                                <div className="col-4 my-3"><Fade direction='left' triggerOnce><div className="card card4">
                                    <div className="row g-0">
                                        <div className="col-3">
                                            <img src={chat} className="img-fluid my-5 px-3" alt="..." />
                                        </div>
                                        <div className="col-9">
                                    <div className="card-body"><h5>Create a group chat:</h5> Set up a dedicated group chat on a messaging platform like WhatsApp or Slack to facilitate communication among members.</div></div></div></div></Fade></div>
                                <div className="col-10 text-center my-5">
                                    <p className="tx0">Bachelor mess management can be a challenging task, but this system going to make is easy and enjoyingable. We believe that By providing quality food and service, bachelor mess managers can make a positive impact on the lives of their residents.</p></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="section">
                <div className="container-fluid bg1 py-5">
                    <div className="row">
                        <div className="col-12 my-5">
                            <h4 className="text-center mb-0">Here are some benits for using </h4><h3 className="display-6 text-center mb-5">Bachelor Mess Managers System</h3>
                            <div className="row justify-content-center">
                                <div className="col-5 my-5 px-5"><Fade direction='left' triggerOnce><div className="card crd1"><img src={img5} className="card-img-top" alt="Girl writing on Description" /> 
                                <div className="card-body text-center"><h3 className="">Get to know your residents:</h3> The best way to provide quality food and service is to get to know your residents. This will help you to understand their dietary needs and preferences.</div></div></Fade></div>

                                <div className="col-5 my-5 px-5"><Fade direction='right' triggerOnce><div className="card crd1"><img src={img6} className="card-img-top" alt="Girl writing on Description" />
                                <div className="card-body text-center"><h3 className="">Be flexible:</h3> Things don't always go according to plan, so it's important to be flexible. Be prepared to make changes to menus or schedules as needed.</div></div></Fade></div>

                                <div className="col-5 my-5 px-5"><Fade direction='left' triggerOnce><div className="card crd1"><img src={img3} className="card-img-top" alt="Girl writing on Description" />
                                <div className="card-body text-center"><h3 className="">Be organized:</h3> A well-organized mess is a happy mess. Make sure that you have a system in place for ordering food, cooking meals, and cleaning up.</div></div></Fade></div>

                                <div className="col-5 my-5 px-5"><Fade direction='right' triggerOnce><div className="card crd1"><img src={img1} className="card-img-top" alt="Girl writing on Description" />
                                <div className="card-body text-center"><h3 className="">Be positive:</h3> A positive attitude can go a long way in bachelor mess management. Show your residents that you're happy to be there and that you enjoy working with them.</div></div></Fade></div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div className="section my-5">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            {/* <div className="btn m-1 bg1 hbg1 tx2 bgx1">I am button</div>
                            <div className="btn m-1 bg2 hbg2 tx1">I am button</div>
                            <div className="btn m-1 bg3 hbg3">I am button</div>
                            <div className="btn m-1 bg4 hbg4">I am button</div>
                            <div className="btn m-1 bg5 hbg5">I am button</div>
                            <div className="btn m-1 bg6 hbg6">I am button</div>
                            <div className="btn m-1 bg7 hbg7">I am button</div>
                            <div className="btn m-1 bg8 hbg8 tx4">I am button</div>
                            <div className="btn m-1 bg9 hbg9">I am button</div>
                            <div className="btn m-1 bg10 hbg10">I am button</div> */}
                        </div>
                        
                    </div>
                </div>
            </div>
            <div className="section">
                <div className="container-fluid">
                    <div className="row">

                    </div>
                </div>
            </div>
            
        </div>
    );
}

export default Home;