import React, { useState, useEffect } from "react";
// import { BiEdit } from "react-icons/bi";
import LstMeal from "../scom/meals";
import {Url} from '../../contents/global.js';

const UMLst = (props) => {
    let mid = props.mid;
    const [meals, setMeals] = useState([]);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        getMeals();
    },[props.uid])
    const getMeals = async () => {
        if(uid!=='' && uid!==undefined) {
            console.log(uid);
            setLoading(true);
            let result = await fetch(Url+"getmeal/"+mid+"/"+uid);
            result = await result.json();
            if(uid===result[0].uid)
                setMeals(result);
            setLoading(false);
        }
    }
    let uid = props.uid;
    
    return (
        <div className="container">
            <div className="row">
                <div className="col-12"><h5>Meals</h5></div>
                <div className="col-12">
                    {loading? <div className="loader"></div>: <LstMeal meallst={meals} method={props.method} />}
                </div>
            </div>
        </div>
    )
}

export default UMLst;