import React, {useState } from "react";
import PayForm from "./account/payform";
import Acctype from "./account/acctype";
import Trnsections from "./account/trnsections";
import {FcAddDatabase, FcAdvance} from "react-icons/fc";
import MealAcc from "./account/mealaccount";
import {Url} from '../contents/global.js';
import secureLocalStorage from "react-secure-storage";

const Accounce = () => {
    const user = JSON.parse(secureLocalStorage.getItem("user"));
    const [trnasction, setTrnasction] = useState([]);
    const [paytoLst, setPaytoLst] = useState([]);
    const [curtab, setCurtab] = useState('account');
    const [mshow, setMshow] = useState(false);
    const [showModal, setShowModal] = useState(false);

    const setTrans = (trid) => {
        setTrnasction(trid);  // set transaction number to enable transaction
        setMshow(true);
        setShowModal(true);
    }

    const setList = (dta) => {
        setPaytoLst(dta);
    }
    const resetData = () => {
        setMshow(false);
        toggleModal();
    }
    const toggleModal = () => {
        if(showModal) setShowModal(false);
    };
    
    return <div className="container" style={{minHeight:'90vh'}}>
        <div className="row">
            <div className="col-12"><h3>Account Management, <sup>Make a transaction</sup>  <FcAdvance /> <button type="button" className="btn btns1 pt-1 pb-2" data-bs-toggle="modal" onClick={() => setTrans(null)} data-bs-target="#transections"><FcAddDatabase /></button></h3></div>
            <div className="col-12">
                <ul className="nav nav-tabs">
                    <li className="nav-item">
                        <div className={'nav-link '+(curtab=='account'? 'active': '')} onClick={()=>setCurtab('account')} >Accounce</div>
                    </li>
                    <li className="nav-item">
                        <div className={'nav-link '+(curtab=='meals'? 'active': '')} onClick={()=>setCurtab('meals')} >Meal</div>
                    </li>
                </ul>
            </div>
            {curtab==='account'? <>
                <div className="col-12"><div className="card crd1 w-100"><div className="card-body pb-2">
                    <Trnsections method={setTrans} addList={setList} uid={user._id} /></div></div>
                </div>
            </>: <div className="card crd1 w-100"><div className="card-body pb-2"><MealAcc uid={user._id} /></div></div>}
            
            </div>
            <div className={"modal fade "+ (showModal===true ? 'show' : '')} style={(showModal===true ? {display:'block'}: {display:'none'})} id="transections" tabIndex="-1" aria-labelledby="transectionsLabel" aria-hidden="true" data-bs-backdrop="false">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="transectionsLabel">Payment Form</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <PayForm trid={trnasction} plist={paytoLst} method={resetData} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
}

export default Accounce