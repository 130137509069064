import React from "react";
import { useEffect, useState } from "react";
import {FcRefresh,FcTodoList} from "react-icons/fc";
import {Url} from '../../contents/global.js';


const TableData = (props) => {
    let sdate = props.sdate;
    let edate = props.edate;
    let mid = props.mid;
    let uid = props.uid;
    const [loading, setLoading] = useState(false);
    const [meallst, setMealLst] = useState([]);
    const [suid, setSuid] = useState('all');

    let [lunch, setLunch] = useState(0);
    let [breakf, setBreakf] = useState(0);
    let [dinner, setDinner] = useState(0);
    const getMeals = async () => { 
        let url = Url+"getmeals/"+mid+"/"+props.sdate;
        if(props.edate!==null) url += "/"+props.edate;
        if(sdate!==undefined){
            setLoading(true);
            let l = 0, b = 0,  d = 0;
            let res = await fetch(url).then((r) => r.json() )
            .then((r) => {
                r.forEach(async (itm)  =>  {
                    l += itm.lunch;
                    b += itm.breakfast;
                    d += itm.dinner;
                    setLunch(l);
                    setBreakf(b);
                    setDinner(d);
                })
                setMealLst(r);
            });
            setLoading(false);
        }
    }
    useEffect(() => {
        getMeals();
    }, [mid, props.sdate, props.edate]);


    
    const updateMeal = async (mids) => {
        let data = JSON.stringify({
            mealid: mids,
            uid: uid,
        });
        let result = await fetch(Url+"aprvmeal", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: data
        })
        getMeals();
    }

    const selUid = (user) => {
        setSuid(user);
        let l = 0, b = 0,  d = 0;
        meallst.forEach((itm) => {
            if(itm.uid._id===user){
                l += itm.lunch;
                b += itm.breakfast;
                d += itm.dinner;
                setLunch(l);
                setBreakf(b);
                setDinner(d);
            }
        });
    }

    return (
        <div>
            {loading? <div className="loader"></div>:<>
                    <div className="btn btn-outline-primary pt-1 pb-2 mt-n5" onClick={()=>selUid('all')}><FcTodoList /></div>
                    <table className="table my-3">
                        <thead className="bg-primary py-3" style={{'--bs-bg-opacity': '0.25'}}>
                        <tr><th>Name </th><th>Breakfast</th><th>Lunch</th><th>Dinner</th><th>Checked</th>
                            {/* <th><button className="btn btn-outline-primary" onClick={getMeals}><FcRefresh /></button></th> */}
                        </tr></thead>
                        <tbody>
                        {meallst.map((meal, index) => (
                            <tr className="border-bottom" key={index}><>{ suid === 'all' || suid===meal.uid._id ? <>
                                <td className="py-2" onClick={()=>selUid(meal.uid._id)}>{meal.uid.name} &nbsp; 
                                <small className="text-muted">({meal.date!=null?new Intl.DateTimeFormat("en-GB", {month: "long", day: "2-digit" }).format(new Date(meal.date)):''})</small> </td>
                                <td className="py-2">{meal.breakfast}</td>
                                <td className="py-2">{meal.lunch}</td>
                                <td className="py-2">{meal.dinner}</td>
                                <td className="py-2">{meal.apvby? "OK": meal.creatby!==uid? <div className="btn btn-primary" onClick={()=>updateMeal(meal._id)}>OK</div>:'Waiting'}</td></>
                            : ""}</></tr>
                        ))}
                        <tr><th>Total</th>
                            <td>{breakf}</td>
                            <td>{lunch}</td>
                            <td>{dinner}</td>
                            <th>= {breakf + lunch + dinner}</th>
                        </tr>
                        </tbody>
                    </table></>}
        </div>
    )
}

export default TableData;