import React, {useState, useEffect} from "react";
import {FcLike, FcTodoList, FcDislike, FcApproval, FcHighPriority, FcSearch} from "react-icons/fc";
import {Url} from '../../contents/global.js';
import secureLocalStorage from 'react-secure-storage';

const MessMember = (props) => {
    const edt = JSON.parse(secureLocalStorage.getItem("user"));
    const [search, setSearch] = useState([]);
    const [users, setUsers] = useState([]);
    const [infos, setInfos] = useState([]);
    const [seluser, setSelUser] = useState(null);
    const [loading, setLoading] = useState(false);
    const [mloading, setMloading] = useState(false);
    // const [district, setDistrict] = useState("");
    var ids = props.ids;
    
    useEffect(() => {
        // if(users.length===0) getUsers();
    },[]);
    const getUsers = async () => {
        let url = (search==="")? Url+"userlst": Url+"userlst/"+search;
        setLoading(true);
        let result = await fetch(url);
        result = await result.json();
        // console.warn(result);
        setUsers(result);
        setLoading(false);
    }
    const getMessUser = async (id) => {
        setSelUser(id);
        let data = JSON.stringify({
            uid: id,
            messid: ids,
            createdby: edt._id
        });
        setMloading(true);
        let result = await fetch(Url+"getmessuser", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: data
        });
        result = await result.json();
        // console.warn(result);
        setInfos(result);
        setMloading(false);
    }
    const addRemoveToUser = async (typ) => {
        let data = JSON.stringify({
            uid: seluser,
            messid: ids,
            status: typ,
            aprovedby: edt._id
        })
        let result = await fetch(Url+"uaddremove", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: data
        });
        result = await result.json();
        getMessUser(seluser);
    }
    
    return (
        <>
            <div className="row justify-content-center">
                <div className="col-12 mb-3">
                <div className="d-flex" role="search">
                    <div className="p-2" ><FcTodoList /> Members </div><div className="d-flex"> <input className="form-control d-inline" name="search" onChange={(e) => setSearch(e.target.value)} placeholder="Search Members By Name" />
                    <button className="btn btn-outline-success ms-1" type="submit" onClick={getUsers}><FcSearch /></button></div>
                </div>
                </div>
                
                <div className="col-4">
               
                    <ul className="list-group">{users.map((e) => (
                        <li className="list-group-item btn btn-blank text-start" key={e._id} onClick={()=>getMessUser(e._id)}>{e.name}</li>
                    ))}</ul>
                
                </div>
                {loading? <div className="col-7"><div className="loader"></div></div>:''}
                <div className="col-8">
                    {mloading? <div className="loader"></div>:

                    <div className="text-secondary py-3">
                        {infos.length===0 && seluser!==null? 
                                    <div className="text-center"><div className="btn btn-outline-primary" onClick={()=>addRemoveToUser('created')}>Add to mess</div></div>
                                    :
                            ""
                        }
                        {infos.map((e) => (
                            <h4 key={e._id}>{e.uid.name} <sup className="d-inline text-black">{e.status==='active'? <FcLike />:<FcDislike />}</sup></h4>
                        ))}
                {(() => {
                    if (seluser !== null){
                        return (
                            <>
                            <div className="my-5 text-center">
                                <div>
                                    {infos.length===0? "": 
                                    (infos[0].status==='created' || infos[0].status==='deactive') ? <div className="btn btn-primary" onClick={()=>addRemoveToUser('active')}>Activate <FcApproval /></div> 
                                    : 
                                    <div className="btn btn-outline-danger" onClick={()=>addRemoveToUser('deactive')}>Deactivate <FcHighPriority /></div>}
                                </div>
                                
                            </div>   
                            </>                         
                        )
                    }                    
                    return null;
                })()}
                    </div>
                    }
                
                </div>
            </div>
        </>
    )
}

export default MessMember;