import React, { useEffect } from "react";
import { Slide, Fade } from "react-awesome-reveal";
import secureLocalStorage from 'react-secure-storage';
import { useNavigate } from 'react-router-dom';

const SupHome = () => {
    const navigate = useNavigate();
    const auth = JSON.parse(secureLocalStorage.getItem('user'));
    useEffect(() => {
        if(auth.role!=='supuser' || auth.active!==true) navigate("/");
    }, [auth]);
    
    return <section><div className="container" style={{minHeight: '80vh'}}><div className="row">
        <Fade><h1>Hello its super home</h1></Fade>
        <Slide triggerOnce cascade>
            <ul >
            <li> Item 1 </li>
            <li> Item 2 </li>
            <li> Item 3 </li>
            <li> Item 4 </li>
            </ul>
        </Slide>
    </div></div></section>   
}

export default SupHome;